import {
    chipClasses,
    dialogActionsClasses,
    dialogClasses,
    dialogContentClasses,
    dialogTitleClasses,
    Icon,
    iconButtonClasses,
    inputLabelClasses,
    linearProgressClasses,
    listItemIconClasses,
    outlinedInputClasses,
    stepIconClasses,
    stepLabelClasses,
    svgIconClasses,
    switchClasses,
    tableCellClasses,
    tooltipClasses
} from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { DEFAULT_FONT_STYLE, DEFAULT_LETTER_SPACING, DEFAULT_LINE_HEIGHT } from './styles/variables';

const theme = createTheme({
    palette: {
        common: {
            white: '#FFFFFF',
            offwhite: '#FAFAFA',
            black: '#363836',
            blue: '#1473CC',
            lightblue: '#F5F9FC',
            lightblue200: '#E1EDF6',
            darkblue: '#0B4377',
            background: '#ECF1F4',
            gray: '#8F8F8F',
            accentgray: '#828282',
            lightgray: '#EAEAEA',
            darkgray: '#F2F2F2',
            lightorange: '#FED7AA',
            red: '#B40000',
            lightred: '#EECCCC',
            darkred: '#A80000'
        },
        button: {
            disabled: '#7D7E7D',
            background: '#E6E7E6'
        },
        success: {
            main: '#02893F',
            secondary: '#EFFAF4',
            L500: '#02A24B'
        },
        error: {
            main: '#B40000',
            secondary: '#A80000'
        },
        chips: {
            primary: '#01612C',
            secondary: '#545454',
            success: '#01612C',
            info: '#0B4377',
            warning: '#E36D00',
            danger: '#A80000'
        },
        disabled: {
            main: '#D9D9D9'
        },
        link: {
            main: '#1473CC'
        },
        info: {
            main: '#1473CC'
        },
        hover: {
            main: '#D2EBF6'
        },
        navigation: {
            background: {
                main: '#ECF1F4',
                hover: '#D2EBF6',
                selected: '#D2EBF6',
                submenu: '#ECF1F4'
            },
            span: {
                main: '#0B4377',
                hover: '#06223C',
                selected: '#0B4377'
            }
        }
    },
    typography: {
        fontFamily: 'Open Sans',
        /**
         * `pxToRem` used in tandem with `responsiveFontSizes()` converts our fontSizes to 'rem' (1rem = 16px) so our
         *    fonts can scale based on varying screen sizes.
         *
         * PX to REM converter: https://nekocalc.com/px-to-rem-converter
         *
         * @param {number} fontSize - 'px' value intended to convert to 'rem'
         * @return {number} 'rem' representation from input 'px' parameter
         *
         * @example
         *
         *     theme.typography.pxToRem(14)
         */
        pxToRem: size => `${size / 16}rem`,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemiBold: 600
    },
    shape: {
        borderRadius: 4
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 380, // Small Mobile
            mobile: 550, // Mobile
            sm: 744, // Tablets/Laptops
            md: 1044, // Laptops/Desktops
            lg: 1279, // Larger Desktop Monitors
            xl: 1439 // TVs
        }
    },
    /*
		Spacing throughout the app will go by factors of 4 (minimum of at lease 4px), as defined below
		e.g. If you have something that needs to be '24px', you would use 'theme.spacing(6)'.
		Spacing will be used most commonly for properties likes margin and padding.
		More about spacing: https://mui.com/material-ui/customization/spacing/
	*/
    spacing: 4,
    components: {
        MuiContainer: {
            variants: [
                {
                    props: { variant: 'fixed-height' },
                    style: () => ({
                        overflow: 'hidden',
                        position: 'relative',
                        height: '100%',
                        '& .MuiCard-root': {
                            display: 'grid',
                            alignItems: 'start',
                            position: 'relative',
                            '&:not(.empty-card)': {
                                height: 'calc(100% - 20px)',
                                [theme.breakpoints.down('sm')]: {
                                    overflow: 'auto'
                                }
                            },
                            '&.empty-card': {
                                height: 'unset'
                            },
                            '&.edit-mode': {
                                [theme.breakpoints.up('sm')]: {
                                    paddingBottom: '75px'
                                },
                                '& .MuiCardContent-root': {
                                    [theme.breakpoints.down('sm')]: {
                                        paddingBottom: '75px'
                                    }
                                }
                            },
                            '& .MuiCardContent-root': {
                                overflowY: 'auto',
                                paddingBottom: '20px',
                                [theme.breakpoints.up('sm')]: {
                                    overflow: 'hidden',
                                    height: '100%',
                                    paddingBottom: '0'
                                },

                                '& .MuiTableContainer-root': {
                                    position: 'relative',
                                    height: '100%',
                                    '&:before': {
                                        // tbody overlay scroll container
                                        content: '""',
                                        border: '1px solid #e0e0e0',
                                        outline: `50px solid ${theme.palette.common.lightblue}`,
                                        borderRadius: '10px',
                                        top: '40px', // position below table header
                                        left: '0',
                                        right: '0',
                                        bottom: '0',
                                        zIndex: '1',
                                        position: 'absolute',
                                        pointerEvents: 'none'
                                    },
                                    '& .scroll': {
                                        overflow: 'auto',
                                        height: '100%',
                                        '& th': {
                                            padding: '8px 16px',
                                            border: '0',
                                            background: 'none'
                                        },
                                        '& tbody': {
                                            background: 'white',
                                            position: 'relative',
                                            zIndex: '0'
                                        }
                                    }
                                }
                            },
                            '& .MuiCardHeader-action': {
                                display: 'flex',
                                alignSelf: 'center',
                                [theme.breakpoints.up('sm')]: {
                                    paddingRight: '16px'
                                },
                                [theme.breakpoints.down('sm')]: {
                                    position: 'absolute',
                                    right: '16px',
                                    top: '16px',
                                    zIndex: '99'
                                }
                            }
                        },
                        '& form': {
                            height: '100%'
                        }
                    })
                }
            ]
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: 'auto-height' },
                    style: () => ({
                        height: 'auto'
                    })
                },
                {
                    props: { variant: 'same-height' },
                    style: () => ({
                        height: '100%'
                    })
                }
            ]
        },
        MuiTable: {
            variants: [
                {
                    props: { variant: 'card-table' },
                    style: () => ({
                        maxHeight: '76px',
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        },
                        borderCollapse: 'separate'
                    })
                },
                {
                    props: { variant: 'tab-table' },
                    style: () => ({
                        '& .MuiTable-root': {
                            borderCollapse: 'collapse'
                        },
                        '& .MuiTableBody-root': {
                            borderWidth: '1px',
                            borderRadius: '12px',
                            outline: '1px solid #D0D0D0',
                            outlineOffset: '-1px'
                        },
                        '& .MuiTableRow-root:hover .MuiTableCell-root': {
                            borderTop: 'none'
                        }
                    })
                }
            ]
        },
        MuiTableRow: {
            variants: [
                {
                    props: { variant: 'card-row' },

                    style: () => ({
                        '&:first-of-type td': {
                            borderTop: '1px solid rgba(143,143,143,0.3)'
                        },
                        '&:first-of-type td:first-of-type': {
                            borderTopLeftRadius: '12px',
                            borderLeft: '1px solid rgba(143,143,143,0.3)'
                        },
                        '&:first-of-type td:last-of-type': {
                            borderTopRightRadius: '12px',
                            borderRight: '1px solid rgba(143,143,143,0.3)'
                        },
                        '&:last-of-type td': {
                            borderBottom: '1px solid rgba(143,143,143,0.3)'
                        },
                        '&:last-of-type td:first-of-type': {
                            borderBottomLeftRadius: '12px',
                            borderLeft: '1px solid rgba(143,143,143,0.3)'
                        },
                        '&:last-of-type td:last-of-type': {
                            borderBottomRightRadius: '12px',
                            borderRight: '1px solid rgba(143,143,143,0.3)'
                        },
                        '& td:first-of-type': {
                            borderLeft: '1px solid rgba(143,143,143,0.3)'
                        },
                        '& td:last-of-type': {
                            borderRight: '1px solid rgba(143,143,143,0.3)'
                        },
                        ':focus': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    })
                },
                {
                    props: { variant: 'tablet-card-row' },
                    style: ({ theme }) => ({
                        '&:nth-of-type(even) td:first-of-type': {
                            borderLeft: '1px solid rgba(143,143,143,0.3)',
                            borderTop: `1px solid ${theme.palette.common.gray}`
                        },

                        '&:nth-of-type(even) td:last-of-type': {
                            borderTop: `1px solid ${theme.palette.common.gray}`,
                            borderRight: '1px solid rgba(143,143,143,0.3)'
                        },

                        '&:nth-of-type(even) td': {
                            cursor: 'pointer'
                        },

                        '&:nth-of-type(odd) td': {
                            borderTop: '1px solid rgba(143,143,143,0.3)'
                        },

                        '&:nth-of-type(odd) td:first-child': {
                            borderLeft: '1px solid rgba(143,143,143,0.3)'
                        },

                        '&:nth-of-type(odd) td:last-child': {
                            borderRight: '1px solid rgba(143,143,143,0.3)'
                        },

                        '&:first-of-type td:first-child': {
                            borderTopLeftRadius: '12px'
                        },

                        '&:first-of-type td:last-child': {
                            borderTopRightRadius: '12px'
                        },

                        '&:last-of-type td:first-of-type': {
                            borderBottomLeftRadius: '12px',
                            borderLeft: '1px solid rgba(143,143,143,0.3)',
                            borderTop: `1px solid ${theme.palette.common.gray}`,
                            borderBottom: '1px solid rgba(143,143,143,0.3)'
                        },

                        '&:last-of-type td:last-of-type': {
                            borderBottomRightRadius: '12px',
                            borderRight: '1px solid rgba(143,143,143,0.3)',
                            borderTop: `1px solid ${theme.palette.common.gray}`,
                            borderBottom: '1px solid rgba(143,143,143,0.3)'
                        }
                    })
                }
            ]
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(16)
                })
            },
            variants: [
                {
                    props: { variant: 'card-total' },
                    style: () => ({
                        fontWeight: theme.typography.fontWeightSemiBold,
                        borderTop: '2px solid rgba(143,143,143,0.3)',
                        '&:.MuiTableCell-root': {
                            display: 'display-flex'
                        }
                    })
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.down('md')]: {
                        '&.animated-minimize': {
                            '&, & #logo': {
                                transition: 'height 0.25s ease'
                            },
                            '&.minimized': {
                                height: '0',
                                '& #mobile-menu': {
                                    padding: '0 8px'
                                },
                                '& .minimized-hidden': {
                                    visibility: 'hidden'
                                }
                            },
                            '& .MuiToolbar-root': {
                                height: '100%',
                                minHeight: 'unset'
                            }
                        }
                    }
                })
            }
        },
        MuiAlert: {
            styleOverrides: {
                standardWarning: ({ theme }) => ({
                    backgroundColor: theme.palette.common.lightorange,
                    border: '1px solid #F97316',
                    fontSize: theme.typography.pxToRem(14)
                }),
                standardInfo: ({ theme }) => ({
                    border: '1px solid #A5A9E4',
                    fontSize: theme.typography.pxToRem(14)
                })
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.Mui-checked': { svg: { color: theme.palette.success.main } }
                })
            }
        },
        MuiCheckbox: {
            defaultProps: {
                checkedIcon: <Icon>check_box</Icon>,
                icon: <Icon>check_box_outline_blank</Icon>,
                indeterminateIcon: <Icon>indeterminate_check_box</Icon>
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                        '& .material-symbols-rounded': {
                            color: theme.palette.success.main,
                            fontVariationSettings: "'FILL' 1"
                        }
                    }
                })
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ['& .flex-layout']: {
                        textTransform: 'capitalize',
                        zIndex: '12',
                        margin: '0',
                        width: '100%',

                        [theme.breakpoints.up('sm')]: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                        },

                        '& label': {
                            fontSize: '1.5rem',
                            lineHeight: '1.4',
                            marginBottom: '0'
                        },
                        '& .is-valid': {
                            width: '100%',
                            [theme.breakpoints.down('sm')]: {
                                marginTop: '10px'
                            }
                        },
                        '& .MuiFormControl-root': {
                            margin: '0'
                        }
                    }
                }),
                action: ({ theme }) => ({
                    [`& .${iconButtonClasses.root}`]: {
                        width: theme.spacing(10),
                        height: theme.spacing(10),
                        padding: 0,
                        marginTop: theme.spacing(1)
                    }
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: '8px',
                    border: `1px solid ${theme.palette.common.gray}`,
                    fontSize: theme.typography.pxToRem(14),
                    [`& .${chipClasses.deleteIcon}`]: {
                        color: `${theme.palette.common.black} !important`
                    }
                })
            },
            variants: [
                {
                    props: { variant: 'selectable' },
                    style: ({ ownerState }) => ({
                        backgroundColor: '#F6F6F6',
                        border: '1px solid #545454',
                        borderRadius: '8px',
                        '&.MuiChip-clickable:hover,&.MuiChip-clickable:focus': {
                            border: '1px solid #0B4377',
                            backgroundColor: '#F5F9FC'
                        },
                        ...(ownerState.selected && {
                            border: '1px solid #0B4377',
                            backgroundColor: '#D2EBF6',
                            color: '#0B4377',
                            '&.MuiChip-clickable:hover,&.MuiChip-clickable:focus': {
                                backgroundColor: '#BDE2F2'
                            },
                            '&.MuiChip-icon': {
                                color: '#0B4377'
                            }
                        }),
                        icon: ({ ownerState }) => ({
                            ...(ownerState.selected && {
                                color: '#0B4377'
                            })
                        })
                    })
                },
                {
                    props: { variant: 'default' },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.common.lightgray,
                        border: `1px solid ${theme.palette.common.gray}`,
                        color: theme.palette.common.black
                    })
                },
                {
                    props: { variant: 'info' },
                    style: ({ theme }) => ({
                        backgroundColor: '#E7F4FA',
                        border: `1px solid ${theme.palette.chips.info}`,
                        color: theme.palette.chips.info
                    })
                },
                {
                    props: { variant: 'warning' },
                    style: ({ theme }) => ({
                        backgroundColor: '#FEF8F2',
                        border: `1px solid ${theme.palette.chips.warning}`,
                        color: theme.palette.chips.warning
                    })
                },
                {
                    props: { variant: 'success' },
                    style: ({ theme }) => ({
                        background: theme.palette.success.secondary,
                        border: '1px solid #01612C',
                        color: '#01612C'
                    })
                },
                {
                    props: { variant: 'error' },
                    style: ({ theme }) => ({
                        background: '#FBF2F2',
                        border: `1px solid ${theme.palette.error.secondary}`,
                        color: theme.palette.error.secondary
                    })
                },
                {
                    props: { variant: 'danger' },
                    style: () => ({
                        backgroundColor: theme.palette.chips.danger,
                        color: 'white'
                    })
                }
            ]
        },
        MuiLink: {
            styleOverrides: {
                root: () => ({ cursor: 'pointer' })
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    [`& .${outlinedInputClasses.root}`]: {
                        padding: '5px 10px !important',
                        height: ownerState.multiple ? 'unset' : '48px'
                    }
                }),
                popper: () => ({
                    '& .MuiListSubheader-root': { padding: '12px 12px 4px 12px' },
                    '& .MuiPaper-root': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)' }
                })
            },
            defaultProps: {
                popupIcon: <Icon>expand_more</Icon>
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ theme }) => ({
                    margin: '8px 0',
                    [`& .${outlinedInputClasses.root}`]: {
                        '&:not(.Mui-disabled):hover fieldset': {
                            borderColor: theme.palette.success.main
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.success.main
                        }
                    },
                    [`& .${inputLabelClasses.shrink}`]: {
                        color: `${theme.palette.common.black} !important`
                    },
                    [`& .${inputLabelClasses.root}`]: {
                        fontSize: 'unset',
                        paddingBottom: 1,
                        fontWeight: theme.typography.fontWeightRegular,
                        letterSpacing: '0.4px',
                        textTransform: 'capitalize !important'
                    }
                })
            },
            variants: [
                {
                    props: 'noSpinButton',
                    style: {
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                            display: 'none'
                        },
                        '& input[type=number]': {
                            MozAppearance: 'textfield'
                        }
                    }
                }
            ]
        },
        MuiTableContainer: {
            styleOverrides: {
                root: () => ({
                    '& .MuiCard-root': {
                        height: 'auto'
                    }
                })
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: ({ theme }) => ({
                    width: 48,
                    height: 28,
                    padding: 0,
                    marginRight: theme.spacing(2),
                    [`& .${switchClasses.switchBase}`]: {
                        padding: 0,
                        margin: theme.spacing(1),
                        transitionDuration: '100ms',
                        '&.Mui-checked': {
                            transform: 'translateX(20px)',
                            margin: theme.spacing(1),

                            '& + .MuiSwitch-track': {
                                backgroundColor: theme.palette.success.secondary,
                                opacity: 1,
                                border: `1px solid ${theme.palette.success.main}`
                            },
                            '& .MuiSwitch-thumb': {
                                color: theme.palette.success.main,
                                width: '20px',
                                height: '20px'
                            },
                            '&:hover .MuiSwitch-thumb, &:focus .MuiSwitch-thumb, &:active .MuiSwitch-thumb': {
                                color: theme.palette.success.L500
                            },
                            '& .MuiSwitch-thumb:before': {
                                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
                            }
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            backgroundColor: theme.palette.common.black
                        }
                    },
                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        // Temp for gray 600
                        color: '#686868',
                        width: '20px',
                        height: '20px',
                        '&:before': {
                            content: "''",
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            left: 0,
                            top: 0,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 100,
                        border: `1px solid ${theme.palette.common.accentgray}`,
                        backgroundColor: theme.palette.common.lightgray,
                        opacity: 1,
                        transition: theme.transitions.create(['background-color'], {
                            duration: 100
                        })
                    }
                })
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: props => <Icon {...props}>expand_more</Icon>
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.common.white,
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: theme.palette.success.main
                    },
                    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: theme.palette.success.main
                    }
                })
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: () => ({
                    margin: 0
                })
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                asterisk: {
                    color: '#A80000'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: theme.typography.fontWeightRegular,
                    lineHeight: '18px',
                    letterSpacing: '1.5px'
                }),
                asterisk: {
                    color: '#A80000'
                }
            },
            variants: [
                {
                    props: { variant: 'list-filter' },
                    style: ({ theme }) => ({
                        fontWeight: theme.typography.fontWeightSemiBold,
                        fontSize: theme.typography.pxToRem(10),
                        textTransform: 'uppercase',
                        marginBottom: 0
                    })
                }
            ]
        },
        MuiInputBase: {
            styleOverrides: {
                root: () => ({
                    height: '48px',
                    backgroundColor: theme.palette.common.white
                }),
                multiline: () => ({
                    height: 'unset',
                    textTransform: 'uppercase',
                    marginBottom: 0
                }),
                asterisk: {
                    color: 'red'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({ theme }) => ({
                    marginTop: '0',
                    letterSpacing: '0.4px',
                    '& .required': {
                        color: theme.palette.chips.danger
                    },
                    '& > .MuiBox-root:not(:empty)': {
                        marginTop: theme.spacing(1)
                    }
                })
            }
        },
        MuiTab: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textTransform: 'capitalize',
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: theme.typography.pxToRem(16),
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    color: theme.palette.common.black,
                    '&.Mui-selected': {
                        background: '#D2EBF6',
                        color: theme.palette.common.darkblue,
                        borderRadius: '40px',
                        height: '40px'
                    }
                })
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    height: '40px',
                    borderRadius: '20px',
                    fontWeight: theme.typography.fontWeightRegular,
                    textTransform: 'capitalize',
                    paddingRight: theme.spacing(4),
                    paddingLeft: theme.spacing(4)
                })
            },
            variants: [
                {
                    props: { variant: 'primary' },
                    style: ({ theme, ownerState }) => ({
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.common.white,
                        '&:hover': {
                            background: theme.palette.success.main,
                            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
                        },
                        '&:focus': {
                            background: '#029745'
                        },
                        ...(ownerState.disabled && {
                            background: theme.palette.button.background,
                            color: theme.palette.common.accentgray
                        })
                    })
                },
                {
                    props: { color: 'error' },
                    style: ({ theme, ownerState }) => {
                        return {
                            background: theme.palette.error.main,
                            color: theme.palette.common.white,
                            '&:hover': {
                                background: theme.palette.error.main,
                                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
                            },
                            '&:focus': {
                                background: '#c20000'
                            },
                            ...(ownerState.disabled && {
                                background: theme.palette.button.background,
                                color: theme.palette.common.accentgray
                            })
                        };
                    }
                },
                {
                    props: { variant: 'secondary' },
                    style: ({ theme, ownerState }) => ({
                        border: `1px solid ${theme.palette.success.main}`,
                        background: theme.palette.common.white,
                        color: theme.palette.success.main,
                        '&:hover': {
                            background: theme.palette.success.secondary
                        },
                        ...(ownerState.disabled && {
                            color: theme.palette.button.disabled,
                            border: `1px solid ${theme.palette.button.background}`
                        })
                    })
                },

                {
                    props: { variant: 'tertiary' },
                    style: ({ theme }) => ({
                        border: 'unset',
                        color: theme.palette.success.main
                    })
                },
                {
                    props: { variant: 'alternate' },
                    style: ({ theme }) => ({
                        color: 'common.black',
                        fontWeight: theme.typography.fontWeightRegular,
                        textTransform: 'none',
                        fontSize: theme.typography.pxToRem(14)
                    })
                },
                {
                    props: { variant: 'text-underline' },
                    style: () => ({
                        fontSize: 'unset',
                        padding: '0',
                        '&:hover': {
                            textDecoration: 'underline'
                        },
                        '&:focus': {
                            textDecoration: 'underline'
                        }
                    })
                },
                {
                    props: { variant: 'dropzone' },
                    style: ({ theme }) => ({
                        display: 'block',
                        width: '100%',
                        height: 'unset',
                        border: `1px dashed ${theme.palette.common.blue}`,
                        borderRadius: '12px',
                        background: theme.palette.common.lightblue,
                        padding: '30px 20px',
                        fontSize: '1rem',
                        lineHeight: '1.4',
                        color: theme.palette.common.blue
                    })
                }
            ]
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: ({ theme }) => ({
                    boxShadow: 'none',
                    marginTop: theme.spacing(4),
                    marginBottom: theme.spacing(4)
                }),
                grouped: ({ theme }) => ({
                    backgroundColor: theme.palette.common.offwhite,
                    color: theme.palette.common.black,
                    border: `1px solid ${theme.palette.common.gray}`,
                    ':not(:last-of-type)': {
                        borderColor: theme.palette.common.gray
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.hover.main
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.hover.main,
                        color: theme.palette.common.darkblue
                    }
                })
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: `${theme.palette.common.black} !important`,
                    fontSize: theme.typography.pxToRem(10),
                    fontWeight: theme.typography.fontWeightSemiBold,
                    lineHeight: '18px',
                    letterSpacing: '1.5px',
                    textTransform: 'uppercase'
                })
            }
        },
        MuiListItemButton: {
            variants: [
                {
                    props: { variant: 'nav' },
                    style: ({ theme, ownerState }) => ({
                        borderRadius: '8px',
                        marginLeft: theme.spacing(2),
                        marginBottom: theme.spacing(1),
                        '&:last-child': {
                            marginBottom: theme.spacing(2)
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.navigation.background[ownerState.submenu ? 'submenu' : 'hover'],
                            span: {
                                color: theme.palette.navigation.span.hover
                            }
                        },
                        [`& .${listItemIconClasses.root}`]: {
                            marginRight: theme.spacing(2)
                        },
                        '&.Mui-selected': {
                            borderRadius: '8px',
                            borderLeft: `8px solid ${theme.palette.navigation.span.selected}`,
                            backgroundColor: `${theme.palette.navigation.background[ownerState.submenu ? 'submenu' : 'selected']} !important`,
                            span: { color: theme.palette.navigation.span.selected },
                            '&:hover': {
                                outline: '2px solid rgba(11, 67, 119, 0.2)',
                                outlineOffset: '2px'
                            }
                        }
                    })
                },
                {
                    props: { variant: 'notification' },
                    style: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            '&': {
                                maxHeight: '96px',
                                marginBottom: '1rem'
                            }
                        },
                        backgroundColor: theme.palette.common.lightblue,
                        border: `1px solid ${theme.palette.common.lightgray}`,
                        borderRadius: 3,
                        padding: '1rem',
                        marginBottom: '12px'
                    })
                }
            ]
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    color: theme.palette.common.black,
                    ...(!ownerState.open && {
                        minWidth: 0
                    })
                })
            },
            variants: [
                { props: { variant: 'regular' }, style: () => ({ span: { fontSize: '24px' } }) },
                { props: { variant: 'large' }, style: () => ({ span: { fontSize: '32px' } }) },
                { props: { variant: 'xlarge' }, style: () => ({ span: { fontSize: '40px' } }) }
            ]
        },
        MuiListItemText: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontWeight: theme.typography.fontWeightRegular,
                    fontStyle: DEFAULT_FONT_STYLE,
                    lineHeight: DEFAULT_LINE_HEIGHT,
                    letterSpacing: DEFAULT_LETTER_SPACING
                })
            }
        },
        MuiIcon: {
            defaultProps: { baseClassName: 'material-symbols-rounded' },
            variants: [
                {
                    props: { variant: 'filled' },
                    style: () => ({
                        fontVariationSettings: '"FILL" 1'
                    })
                },
                {
                    props: { variant: 'keyboard' },
                    style: () => ({
                        width: '20px',
                        height: '20px',
                        lineHeight: '20px',
                        fontSize: '14px',
                        textAlign: 'center',
                        borderRadius: '3px',
                        backgroundColor: theme.palette.common.lightgray,
                        color: theme.palette.common.black,
                        border: `1px solid ${theme.palette.common.accentgray}`
                    })
                }
            ]
        },
        MuiIconButton: {
            defaultProps: { className: 'material-symbols-rounded' },
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.common.black,
                    // Default styling outside of being placed inside of a Autocomplete
                    '&:not(.MuiAutocomplete-endAdornment .MuiIconButton-root)': {
                        height: '48px',
                        width: '48px'
                    },
                    // Specific styles for MuiIconButton inside MuiAutocomplete-endAdornment
                    '&.MuiAutocomplete-endAdornment .MuiIconButton-root': {
                        width: '24px',
                        height: '24px'
                    }
                })
            },
            variants: [
                {
                    props: { variant: 'regular' },
                    style: ({ theme, selected }) => ({
                        borderRadius: '10px',
                        border: `1px solid ${selected ? '#0b437761' : theme.palette.common.lightgray}`,
                        backgroundColor: selected ? '#D2EBF6' : theme.palette.common.lightblue,
                        color: selected ? theme.palette.common.darkblue : theme.palette.common.darkblue,
                        '&:hover': {
                            backgroundColor: selected ? '#BDE2F2' : theme.palette.common.lightblue,
                            color: theme.palette.common.darkblue,
                            border: '1px solid #0b437761'
                        },
                        '&:focus': { border: '1px solid #0b437761' },
                        '&.Mui-disabled': { backgroundColor: theme.palette.disabled.main }
                    })
                },
                {
                    props: { variant: 'small' },
                    style: () => ({
                        height: '24px',
                        width: '24px'
                    })
                },
                {
                    props: { variant: 'action' },
                    style: () => ({
                        borderRadius: 16,
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.common.white,
                        marginTop: theme.spacing(3),
                        marginRight: theme.spacing(2),
                        marginBottom: theme.spacing(3),
                        height: '56px',
                        width: '56px',
                        '&:hover': {
                            backgroundColor: theme.palette.success.main,
                            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
                        },
                        '&:focus': {
                            backgroundColor: '#029745'
                        }
                    })
                },
                {
                    props: { variant: 'showHide' },
                    style: ({ theme }) => ({
                        borderRadius: '10px 10px 0 0',
                        border: `none`,
                        backgroundColor: theme.palette.common.white,
                        transform: 'translateY(-100%)',
                        color: theme.palette.common.darkblue,
                        '&:hover': {
                            backgroundColor: theme.palette.common.lightblue,
                            color: theme.palette.common.darkblue,
                            border: '1px solid #0b437761'
                        },
                        '&:focus': { border: 'none' },
                        '&.Mui-disabled': { backgroundColor: theme.palette.disabled.main }
                    })
                },
                {
                    props: { variant: 'noBorder' },
                    style: () => ({
                        color: theme.palette.common.darkblue,
                        borderRadius: 'none',
                        '&:hover': {
                            borderRadius: 'none'
                        },
                        '&:focus': {
                            borderRadius: 'none'
                        }
                    })
                },
                {
                    props: { variant: 'filesCard' },
                    style: () => ({
                        background: theme.palette.common.white,
                        width: '32px',
                        height: '32px'
                    })
                },
                {
                    props: { variant: 'text-label' },
                    style: ({ theme }) => ({
                        width: 'unset !important',
                        height: 'unset !important',
                        borderRadius: '6px',
                        border: `1px solid ${theme.palette.common.blue}`,
                        backgroundColor: theme.palette.common.lightblue,
                        color: theme.palette.common.blue,
                        padding: '4px 8px',
                        gap: '5px',
                        '&:hover, &:focus': {
                            borderColor: theme.palette.common.darkblue
                        },
                        '& *': { color: `${theme.palette.common.blue} !important`, fontSize: `${theme.typography.pxToRem(14)} !important` },
                        '&:hover, &:focus, &:hover *, &:focus *': {
                            color: `${theme.palette.common.darkblue} !important`
                        }
                    })
                }
            ]
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    paddingTop: '8px',
                    paddingRight: '12px',
                    paddingBottom: '8px',
                    paddingLeft: '8px',
                    fontSize: '12px',
                    backgroundColor: '#4D4D4D',
                    fontWeight: theme.typography.fontWeightRegular,
                    fontStyle: DEFAULT_FONT_STYLE,
                    lineHeight: '16px',
                    [`& .${tooltipClasses.arrow}`]: { color: '#4D4D4D' }
                })
            }
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                staticTooltipLabel: {
                    whiteSpace: 'nowrap',
                    maxWidth: 'none',
                    fontSize: '14px',
                    boxShadow: 'none',
                    backgroundColor: '#F6F6F6',
                    border: '1px solid #D0D0D0',
                    borderRadius: '8px',
                    position: 'absolute'
                }
            },
            variants: [
                {
                    props: { variant: 'enabled' },
                    style: ({ theme, disabled }) => ({
                        border: `1px solid ${disabled ? '#0b437761' : theme.palette.common.lightgray}`,
                        backgroundColor: disabled ? '#D2EBF6' : theme.palette.common.lightblue,
                        color: disabled ? theme.palette.common.darkblue : theme.palette.common.darkblue,
                        '&:hover': {
                            backgroundColor: disabled ? '#BDE2F2' : theme.palette.common.lightblue,
                            color: theme.palette.common.darkblue,
                            border: '1px solid #0b437761'
                        },
                        '&:focus': { border: '1px solid #0b437761' },
                        '&.Mui-disabled': { backgroundColor: theme.palette.disabled.main }
                    })
                }
            ]
        },
        MuiSpeedDial: {
            styleOverrides: {
                root: () => ({
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem'
                }),
                fab: ({ theme }) => ({
                    backgroundColor: theme.palette.common.lightblue,
                    color: theme.palette.common.blue,
                    border: `1px solid ${theme.palette.common.lightgray}`,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: theme.palette.common.blue,
                        color: theme.palette.common.lightblue
                    }
                })
            }
        },
        MuiStep: {
            styleOverrides: {
                root: ({ theme }) => ({
                    // Circle color (active)
                    [`& .${svgIconClasses.root}.Mui-active`]: {
                        color: theme.palette.common.lightgray,
                        border: `1px solid ${theme.palette.common.accentgray}`,
                        borderRadius: '50%'
                    },
                    // Circle color (complete)
                    [`& .${stepLabelClasses.root} .${stepLabelClasses.completed}`]: {
                        fill: theme.palette.success.main
                    },
                    // Circle text (active)
                    [`& .${stepLabelClasses.root} .${stepIconClasses.active} .${stepIconClasses.text}`]: {
                        fill: 'black'
                    }
                })
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontStyle: DEFAULT_FONT_STYLE,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightRegular,
                    lineHeight: DEFAULT_LINE_HEIGHT,
                    letterSpacing: DEFAULT_LETTER_SPACING,
                    color: theme.palette.common.black
                }),
                dt: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: theme.typography.fontWeightSemiBold,
                    letterSpacing: 0.25,
                    lineHeight: '22px'
                }),
                headlineLarge: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(32),
                    textTransform: 'capitalize'
                }),
                headlineMedium: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(24)
                }),
                headlineSmall: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(20)
                }),
                titleLarge: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(22)
                }),
                titleSmall: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(14)
                }),
                labelMedium: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(12)
                })
            },
            variants: [
                {
                    props: { variant: 'text-divider' },
                    style: ({ theme }) => ({
                        alignItems: 'center',
                        height: '20px',
                        color: theme.palette.common.black,
                        fontWeight: theme.typography.fontWeightRegular,
                        letterSpacing: '1.5px',
                        fontSize: '10px',
                        textTransform: 'uppercase'
                    })
                },
                {
                    props: { variant: 'italic' },
                    style: () => ({
                        fontStyle: 'italic'
                    })
                },
                {
                    props: { variant: 'uppercase' },
                    style: () => ({
                        fontSize: theme.typography.pxToRem(10),
                        fontWeight: theme.typography.fontWeightSemiBold,
                        textTransform: 'uppercase',
                        lineHeight: '18px',
                        letterSpacing: 1.5
                    })
                },
                {
                    props: { variant: 'label' },
                    style: ({ theme }) => ({
                        fontSize: '10px',
                        textTransform: 'uppercase',
                        fontWeight: theme.typography.fontWeightMedium
                    })
                }
            ]
        },
        MuiDialog: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    [`& .${dialogTitleClasses.root}`]: {
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingRight: theme.spacing(3),
                        paddingLeft: theme.spacing(6),
                        paddingBottom: 0,
                        marginBottom: theme.spacing(2),
                        fontSize: theme.typography.pxToRem(24)
                    },
                    [`& .${dialogActionsClasses.root}`]: {
                        padding: theme.spacing(6)
                    },
                    [`& .${dialogContentClasses.root}`]: {
                        marginTop: theme.spacing(1)
                    },
                    [`& .${dialogClasses.paper}`]: {
                        position: 'absolute',
                        top: 0,
                        borderRadius: ownerState.fullScreen ? 0 : '20px'
                    }
                })
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.disabled.main,
                    width: '100%',

                    [`& .${linearProgressClasses.bar}`]: {
                        backgroundColor: theme.palette.success.main
                    }
                })
            },
            variants: [
                {
                    props: { download: true },
                    style: () => ({
                        height: '11px',
                        borderRadius: '12px',
                        [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: '#56A5EE',
                            borderRadius: '12px'
                        }
                    })
                }
            ]
        }
    }
});

// TODO: Brought over from old theme.js file, eventually move to making this a variant or shared style
export const groupedSelectStyles = {
    groupHeading: styles => ({
        ...styles,
        fontSize: '0.8em',
        fontWeight: 'bold',
        borderBottom: 'solid 1px #aaa',
        margin: '5px',
        paddingLeft: '4px',
        paddingBottom: '8px',
        color: '#333'
    }),
    option: styles => ({
        ...styles,
        paddingLeft: '2em'
    })
};

export default responsiveFontSizes(theme);
