/**
 * Controls the removal of navigation items in our Drawer(s)
 *
 * @param {object} permissions - Flat object of permissions;
 *		keys represent the setting in Redux that control
 *		values are either true/false or '0'/'1'
 *
 *		{
 *			isLedgerEnabled: '1',
 *			isBillsEnabled: '0',
 *			isMaintenanceEnabled: true,
 *			isAdminEnabled: false,
 *		}
 *
 * @param {object} items See `NavigationItems.js`
 * @return {array} Filtered down `NavigationItems.js` based off permissions
 * @return {Object.<string, number>} Dictionary of overrides with the key being the `permissionFlag`; This allows for us to explicity return
 * 		a `navigationItem` structure entirely ourselves based on multiple permissions. See Resident's Container App.js for usage
 *
 * @example
 *     setItems(navigationPermissions({ ...portalSettings, ...portalOwnerSettings }, NAVIGATION_ITEMS))
 *
 */
export default function navigationPermissions(permissions, items, overrides = {}) {
    return items.reduce((accumulator, current) => {
        if (current.permissionFlag) {
            const permission = permissions[current.permissionFlag];

            if (overrides[current.permissionFlag]) {
                const overrideRoute = overrides[current.permissionFlag]();

                if (overrideRoute) accumulator.push(overrideRoute);
            } else if (typeof permission == 'string') {
                // Since a string value of '0' is truthy, we check if the incoming permission is typeof String and if so, convert to a Number since Number(0) is not truthy
                if (Number(permission)) {
                    accumulator.push(current);
                }
            } else if (permission) {
                accumulator.push(current);
            }
        } else {
            accumulator.push(current);
        }
        return accumulator;
    }, []);
}
