// @ts-check

import { useState } from 'react';
import { Button } from '@mui/material';

import stopPropagation from './stopPropagation';

export default function copyToClipboard(string) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) return navigator.clipboard.writeText(string);

    return Promise.reject('The Clipboard API is not available.');
}

export function ClickToCopy({ string, children }) {
    const [copied, setCopied] = useState(false);

    const handleClick = event => {
        stopPropagation(event);
        copyToClipboard(string).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        });
    };

    return (
        <Button onClick={handleClick} variant="text" sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', gap: 1, textTransform: 'none' }}>
            {copied ? 'Copied!' : children}
        </Button>
    );
}
