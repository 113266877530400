import { toast } from 'react-toastify';

export default function fileSizeValidator(file) {
    if (file.size >= 40000000) {
        toast.error('File is too large to be uploaded. Maximum upload size is 40 MB');

        return {
            code: 'size-too-large',
            message: `File is too large to be uploaded. Maximum upload size is 40 MB`
        };
    }

    return null;
}
